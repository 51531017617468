export const StockList = [
    {
        name: 'Nifty 50 (Index)',
        symbol: 'NIFTY50'
    },
    {
        name: 'Reliance',
        symbol: 'RELIANCE'
    },
    {
        name: 'Icici Bank',
        symbol: 'ICICIBANK'
    },
    {
        name: 'Vedanta Ltd',
        symbol: 'VEDL'
    },
    {
        name: 'Titan Company Ltd',
        symbol: 'TITAN'
    }
]