<template>
    <v-list density="compact" nav>
        <v-list-item prepend-icon="mdi-view-dashboard" title="Home" value="home"></v-list-item>
        <v-list-item prepend-icon="mdi-forum" title="About" value="about"></v-list-item>
    </v-list>
</template>

<script>
export default {
    name: 'Sidenav'
}
</script>

<style></style>